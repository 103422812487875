import styled from 'styled-components';

export const ButtonComponent = styled.button`
  min-width: 6rem;
  border-radius: ${(props) => props?.primaryButton?.roundness || 0}px;
  outline: none;
  border: none;
  padding: 0.75rem 1rem;
  cursor: pointer;
  &.btn__primary {
    background: ${(props) => props?.primaryButton?.backgroundColor};
    color: ${(props) => props?.primaryButton?.color};
    font-size: ${(props) => props?.primaryButton?.textFontSize || 1}rem;
    font-family: ${(props) => props?.primaryButton?.fontFamily || 'Arial'};
    font-weight: ${(props) => props?.primaryButton?.fontWeight || '500'};
  }
  &.btn__primary:hover {
    background: ${(props) => props?.primaryButton?.backgroundHoverColor};
    transition: background 0.5s ease-in-out;
  }
  &.btn__secondary {
    background: ${(props) => props?.secondaryButton?.backgroundColor};
    border: 1px solid ${(props) => props?.secondaryButton?.borderColor};
    color: ${(props) => props?.secondaryButton?.borderColor};
    font-size: ${(props) => props?.secondaryButton?.textFontSize || 1}rem;
    font-family: ${(props) => props?.secondaryButton?.fontFamily || 'Arial'};
    font-weight: ${(props) => props?.secondaryButton?.fontWeight || '500'};
  }
  &.btn__secondary:hover {
    background: ${(props) => props?.secondaryButton?.borderColor};
    transition: background 0.5s ease-in-out;
    color: ${(props) => props?.primaryButton?.color};
  }
`;
