const Description = (props) => (
  <p
    onClick={(e) => {
      e.stopPropagation();
      props?.handleEditText?.();
      props?.getInputPreFilledValue?.(props.descriptionData?.text);
    }}
    className="Description"
  >
    {props.descriptionData?.text}
  </p>
);

export default Description;
