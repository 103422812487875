import React, { useEffect, useState } from 'react';
import Heading from '../../../CommonComponents/Heading/Heading';
import Description from '../../../CommonComponents/Description/Description';
import Button from '../../../CommonComponents/Button/Button';
import { transformWidgetProps } from '@/components/WidgetMaker/utils/common';
import { getStyleForProps } from '@/components/DesignSystem/ModernTheme/utils/getStylesConfig';

import {
  ButtonContainer,
  MiddleBodyParent,
  TopSection,
  BottomSection,
  MiddleBodyVideoBackground,
  BannerContainer,
} from './ModernMiddleBody5.styled';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFormWidget } from '@/redux/actions';
import { useSSRSelector } from '@/redux/ssrStore';

function ModernMiddleBody5(props) {
  const [middleBodyState, setMiddleBodyState] = useState(
    transformWidgetProps(props?.config?.children)
  );
  const [styleConfig] = useState({
    config: {
      bannerAspectRatio: {
        desktop: 768,
        mobile: 768,
      },
      rotationSpeed: 3,
    },
  });
  const dispatch = useDispatch();
  const openForm = () => {
    dispatch(toggleFormWidget(true));
  };
  useEffect(() => {
    const video = document.querySelector('video');
    if (video) {
      video.setAttribute('playsinline', '');
    }
  }, []);
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const { widgetData } = useSelector((state) => ({
    widgetData: state.widgetsReducer,
  }));
  const [propStyle, setPropStyle] = useState(storeData?.theme?.style_template);

  useEffect(() => {
    if (props?.config?.children) {
      setMiddleBodyState(transformWidgetProps(props?.config?.children));
    }
  }, [props?.config?.children]);

  useEffect(() => {
    if (widgetData?.activeStyleTemplate) {
      setPropStyle(getStyleForProps(widgetData?.activeStyleTemplate));
    } else {
      setPropStyle(getStyleForProps(storeData?.theme?.style_template));
    }
  }, [widgetData?.activeStyleTemplate, storeData?.theme?.style_template]);

  const renderParentContainer = (bgType) => {
    return (
      <BannerContainer>
        {(middleBodyState?.heading?.text || middleBodyState?.description?.text) && (
          <TopSection textStyles={propStyle}>
            {middleBodyState?.heading?.text && (
              <Heading headingData={middleBodyState?.heading} />
            )}
            {middleBodyState?.description?.text && (
              <Description descriptionData={middleBodyState?.description} />
            )}
          </TopSection>
        )}
        {middleBodyState?.button?.content && (
          <BottomSection>
            <ButtonContainer>
              {middleBodyState?.button?.content.map((btnData, idx) => (
                <Button
                  key={btnData?.id || idx}
                  btnData={btnData}
                  primaryButton={propStyle?.buttonPrimary}
                  secondaryButton={propStyle?.buttonSecondary}
                  callback={openForm}
                />
              ))}
            </ButtonContainer>
          </BottomSection>
        )}
      </BannerContainer>
    );
  };
  return middleBodyState?.media?.background?.type === 'video' ||
    middleBodyState?.media?.background?.type === 'image' ? (
    <MiddleBodyVideoBackground
      id="MT__MIDDLEBODY5"
      background={middleBodyState?.media?.background}
      styleConfig={styleConfig?.config}
      themeBackground={propStyle?.backgroundColor}
      className={`modern_theme_middlebody5 bg_type_${middleBodyState?.media?.background?.type}`}
    >
      {' '}
      {middleBodyState?.media?.background?.type === 'video' ? (
        <video
          autoPlay
          loop
          muted
          onError={(e) =>
            (e.target.src = middleBodyState?.media?.background?.content[0]?.url)
          }
          playsInline
        >
          <source
            src={middleBodyState?.media?.background?.content[0]?.url}
            type="video/mp4"
          />
        </video>
      ) : (
        <img
          className="bg"
          src={middleBodyState?.media?.background?.content[0]?.url}
          onError={(e) =>
            (e.target.src = middleBodyState?.media?.background?.content[0]?.url)
          }
        />
      )}{' '}
      <MiddleBodyParent
        id="MT__MIDDLEBODY5"
        styleConfig={styleConfig?.config}
        propsBackground={middleBodyState?.media?.background}
        themeBackground={propStyle?.backgroundColor}
        className={`modern_theme_middlebody5 bg_type_${middleBodyState?.media?.background?.type}`}
      >
        {renderParentContainer('video')}
      </MiddleBodyParent>
    </MiddleBodyVideoBackground>
  ) : (
    <MiddleBodyParent
      id="MT__MIDDLEBODY5"
      styleConfig={styleConfig?.config}
      propsBackground={middleBodyState?.media?.background}
      themeBackground={propStyle?.backgroundColor}
      className={`modern_theme_middlebody5 bg_type_${middleBodyState?.media?.background?.type}`}
    >
      {renderParentContainer('')}
    </MiddleBodyParent>
  );
}

export default ModernMiddleBody5;
