import { ButtonComponent } from './Button.styled';
import { btnActionHandler } from '../../utils/btnUtils';
const Button = (props) =>
  props?.btnData?.btnText ? (
    <ButtonComponent
      className={`btn__${props?.btnData?.type}`}
      primaryButton={props?.primaryButton}
      secondaryButton={props?.secondaryButton}
      onClick={() => {
        btnActionHandler(props?.btnData?.actions, props?.callback);
        props?.handleClose && props?.handleClose();
      }}
    >
      {props?.btnData?.btnText}
    </ButtonComponent>
  ) : (
    <></>
  );

export default Button;
